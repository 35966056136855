@import "src/styles/helpers"
.codex-editor__redactor

  color: $n8
  word-break: break-word
  padding-top: 12px
  padding-bottom: 180px !important
  border: 2px #EFEFEF solid
  border-radius: 12px
  margin-bottom: 16px
  font-weight: 500

.codex-editor
  z-index: 0
  display: flow
.codex-editor--narrow
  .codex-editor__redactor
    padding-bottom: 180px !important
    margin-right: unset
  .ce-toolbar__actions
    right: unset
    margin-left: -48px

.ce-block__content
    max-width: calc(100%)
    padding-left: 84px
    padding-right: 24px
    @media only screen and (max-width: "1600px")
    @media only screen and (max-width: "1104px")
.ce-toolbar__content
  margin-left: 74px
  @media only screen and (max-width: "1700px")
    max-width: calc(75%)
  @media only screen and (max-width: "1104px")
    max-width: calc(65%)
  //@media only screen and (max-width: "1600px")
  //  max-width: calc(80%)

