@import "src/styles/helpers";
.limitedHeight {
  position: relative;
  overflow: hidden;
}

.content ul {
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 12px;
}

.content li {
  margin-bottom: 8px;
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  pointer-events: none;
  background: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0) 0%,
                  $n1 100%
  );
}

.content mark.cdx-marker {
  background: #FBFF00;
  padding: 0 4px;
}
