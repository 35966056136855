@import src/styles/helpers

.copy
  margin-left: 6px
  cursor: pointer
  &:hover
      fill: $p3
.name
  display: flex
  flex-direction: row
  +title1-s
  color: $n7
  margin-bottom: 24px
  gap: 8px
  justify-content: space-evenly

  span
    +body1-s
    color: $n4
    display: inline-block
    margin: auto

.entityLink
  cursor: not-allowed
  color: inherit

  &.clickable
    &:hover
      cursor: pointer
      color: $p3




.container
  display: flex
  flex-direction: row
  @media only screen and (max-width: "840px")
    flex-direction: column
  >div:nth-of-type(1)
    //flex-basis: calc(100% - 734px)
  >div:nth-of-type(2)
    flex-basis: 734px
    padding-left: 12px
  padding: 24px 4px 24px 12px

.border_container_comment
  @media only screen and (max-width: '840px')
    border-bottom: unset
  border-bottom: 1px $n3 solid
  border-right: 1px $n3 solid
.comment_list
  @media only screen and (max-width: '840px')
    border-right: unset
  padding-top: 12px
  padding-right: 28px
  >div
    margin-top: unset
  border-right: 1px $n3 solid
  
.gridContainer
  display: grid
  column-gap: 20px

.gridContainer
    grid-template-columns: 1fr 0.5fr
    grid-template-areas: "description type" "comment type"

    +x
        display: flex
        flex-direction: column
    @media only screen and (max-width: '840px')
      grid-template-columns: 1fr
      grid-template-areas: "description" "type" "comment"
      .taskDescription
        grid-area: description
        margin-bottom: 24px

      .taskType
        border-bottom: 1px $n3 solid
        margin-bottom: 24px
        grid-area: type

      .comment
        grid-area: comment

    .taskDescription
      grid-area: description

    .taskType
      grid-area: type

    .comment
      grid-area: comment
.comments
  margin-bottom: 12px
  div[class*=Filters_component],>div
    background-color: $n
  border-right: 1px solid $n3
