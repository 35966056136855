@import ../../styles/helpers

.filters
    position: relative
    svg[name='filter']
        fill: $n4
    &.active
        svg[name='filter']
            fill: $n7
        .body
            visibility: visible
            opacity: 1

.body

    position: absolute
    top: 68px
    overflow-y: auto
    max-height: 400px
    left: -164px
    z-index: 20
    width: 408px
    padding: 24px
    border-radius: 16px
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n1
    visibility: hidden
    opacity: 0
    +m
        position: fixed
        top: 0
        right: 0
        left: 0
        bottom: 0
        width: auto
        overflow: auto 
    +dark
        background: $n7
    @include custom-scrollbar(408px,0px)
    &:hover
        padding-right: 19px


.top
    display: flex
    align-items: center
    margin-bottom: 24px

.title
    margin-right: auto

.close
    flex-shrink: 0
    width: 36px
    height: 36px
    border-radius: 50%
    background: $n3
    margin-left: 24px
    svg
        fill: $n4
        transition: transform .2s
    +dark
        background: $n6
        svg
            fill: $n1
    &:hover
        svg
            transform: rotate(90deg)

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 18
    background: rgba($n2, .8)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: rgba($n6, .9)